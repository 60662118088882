<section class="w-full flex flex-col justify-center items-center bg-[#F0EEE9] py-10 mt-8 mb-4">
	<h1 class="text-2xl sm:text-4xl text-black font-medium mb-5 md:mb-10 text-center px-3">
		 <span class="text-[#b7a98f]">End to End</span> Manufacturing Process
	</h1>
	<div class="container w-full px-8 mt-6 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-10 xl:gap-3 content-center ">
		<a href="https://anuprerna.com/content/wholesale/order-fabric-swatches/59195"
		   target="_blank"
			class="relative flex w-full xl:max-w-[320px] flex-col rounded-xl bg-gradient-to-br from-white to-gray-50 bg-clip-border text-gray-700 shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
		>
			<div
				class="relative mx-4 -mt-6 h-44 overflow-hidden rounded-xl bg-clip-border shadow-lg group"
			>
				<img src="https://anuprerna-bloomscorp.s3.ap-south-1.amazonaws.com/58MVFCXNDRNI7Y31EHE6NFHP7LZD01312.jpg"
					 lazyLoad
					 alt="Order Fabric Swatches">
			</div>
			<div class="p-4">
				<h5
					class="mb-2 block  text-base  md:text-lg font-semibold leading-snug tracking-normal text-gray-900 antialiased group-hover:text-blue-600 transition-colors duration-300"
				>
					Order Fabric Swatches
				</h5>
				<p
					class="block  text-sm font-light leading-relaxed text-gray-700 antialiased"
				>
					We offer fabric swatches for you to explore. Receive samples of our exquisite handloom fabrics to assess their texture, colour, and quality.
				</p>
			</div>
			<div class="p-4 pt-0">
				<button
					class="group relative w-full inline-flex items-center justify-center px-4 py-2 font-bold text-white rounded-lg bg-gradient-to-r from-[#9C8A6C] to-[#B7A98F] hover:from-[#8c7961] hover:to-[#9C8A6C] shadow-lg shadow-[#B7A98F]/30 hover:shadow-[#B7A98F]/40 transition-all duration-300 hover:-translate-y-0.5"
				>
      <span class="relative flex items-center gap-2">
        Read More
        <svg
			viewBox="0 0 24 24"
			stroke="currentColor"
			fill="none"
			class="w-5 h-5 transform transition-transform group-hover:translate-x-1"
		>
          <path
			  d="M17 8l4 4m0 0l-4 4m4-4H3"
			  stroke-width="2"
			  stroke-linejoin="round"
			  stroke-linecap="round"
		  ></path>
        </svg>
      </span>
				</button>
			</div>
		</a>
		<a href="https://anuprerna.com/content/wholesale/wholesale-production-preorder/59335"
		   target="_blank"
			class="relative flex w-full xl:max-w-[320px] flex-col rounded-xl bg-gradient-to-br from-white to-gray-50 bg-clip-border text-gray-700 shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
		>
			<div
				class="relative mx-4 -mt-6 h-44 overflow-hidden rounded-xl bg-clip-border shadow-lg group"
			>
				<img src="https://anuprerna-bloomscorp.s3.ap-south-1.amazonaws.com/home/bulk-order.png" lazyLoad alt="Bulk & Custom Order">
			</div>
			<div class="p-4">
				<h5
					class="mb-2 block  text-base  md:text-lg font-semibold leading-snug tracking-normal text-gray-900 antialiased group-hover:text-blue-600 transition-colors duration-300"
				>
					Bulk Orders & Customisations
				</h5>
				<p
					class="block  text-sm font-light leading-relaxed text-gray-700 antialiased"
				>
					Planning ahead? Our preorder service is crafted for bulk orders, ensuring timely delivery and optimal stock levels for large-scale orders.
				</p>
			</div>
			<div class="p-4 pt-0">
				<button
					class="group relative w-full inline-flex items-center justify-center px-4 py-2 font-bold text-white rounded-lg bg-gradient-to-r from-[#9C8A6C] to-[#B7A98F] hover:from-[#8c7961] hover:to-[#9C8A6C] shadow-lg shadow-[#B7A98F]/30 hover:shadow-[#B7A98F]/40 transition-all duration-300 hover:-translate-y-0.5"
				>
      <span class="relative flex items-center gap-2">
        Read More
        <svg
			viewBox="0 0 24 24"
			stroke="currentColor"
			fill="none"
			class="w-5 h-5 transform transition-transform group-hover:translate-x-1"
		>
          <path
			  d="M17 8l4 4m0 0l-4 4m4-4H3"
			  stroke-width="2"
			  stroke-linejoin="round"
			  stroke-linecap="round"
		  ></path>
        </svg>
      </span>
				</button>
			</div>
		</a>
		<a href="https://anuprerna.com/content/wholesale/natural-sustainable-custom-dyeing/59105"
		   target="_blank"
			class="relative flex w-full xl:max-w-[320px] flex-col rounded-xl bg-gradient-to-br from-white to-gray-50 bg-clip-border text-gray-700 shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
		>
			<div
				class="relative mx-4 -mt-6 h-44 overflow-hidden rounded-xl bg-clip-border shadow-lg group"
			>
				<img src="https://anuprerna-bloomscorp.s3.ap-south-1.amazonaws.com/home/custom-dyeing.png"
					 lazyLoad
					 alt="100% Natural Custom Dyeing">
			</div>
			<div class="p-4">
				<h5
					class="mb-2 block  text-base  md:text-lg font-semibold leading-snug tracking-normal text-gray-900 antialiased group-hover:text-blue-600 transition-colors duration-300"
				>
					100% Natural Custom <br class="hidden 2xl:block"/>Dyeing
				</h5>
				<p
					class="block  text-sm font-light leading-relaxed text-gray-700 antialiased"
				>
					We offer natural and sustainable custom dyeing options that allow you to create the perfect shade for your fabrics and apparel.
				</p>
			</div>
			<div class="p-4 pt-0 ">
				<button
					class="group bottom-0 relative w-full inline-flex items-center justify-center px-4 py-2  font-bold text-white rounded-lg bg-gradient-to-r from-[#9C8A6C] to-[#B7A98F] hover:from-[#8c7961] hover:to-[#9C8A6C] shadow-lg shadow-[#B7A98F]/30 hover:shadow-[#B7A98F]/40 transition-all duration-300 hover:-translate-y-0.5"
				>
      <span class="relative flex items-center gap-2">
        Read More
        <svg
			viewBox="0 0 24 24"
			stroke="currentColor"
			fill="none"
			class="w-5 h-5 transform transition-transform group-hover:translate-x-1"
		>
          <path
			  d="M17 8l4 4m0 0l-4 4m4-4H3"
			  stroke-width="2"
			  stroke-linejoin="round"
			  stroke-linecap="round"
		  ></path>
        </svg>
      </span>
				</button>
			</div>
		</a>
		<a href="https://anuprerna.com/content/wholesale/custom-clothing-accessories-homewares/703160"
		   target="_blank"
			class="relative flex w-full xl:max-w-[320px] flex-col rounded-xl bg-gradient-to-br from-white to-gray-50 bg-clip-border text-gray-700 shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
		>
			<div
				class="relative mx-4 -mt-6 h-44 overflow-hidden rounded-xl bg-clip-border shadow-lg group"
			>
				<img src="https://anuprerna-bloomscorp.s3.ap-south-1.amazonaws.com/home/customisations.png"
					 lazyLoad
					 alt="Custom Clothing & Accessories">
			</div>
			<div class="p-4">
				<h5
					class="mb-2 block  text-base  md:text-lg font-semibold leading-snug tracking-normal text-gray-900 antialiased group-hover:text-blue-600 transition-colors duration-300"
				>
					Custom Clothing & <br class="hidden 2xl:block"/> Accessories
				</h5>
				<p
					class="block  text-sm font-light leading-relaxed text-gray-700 antialiased"
				>
					We specialise in customised designs for clothing, accessories, and homewares, crafted meticulously to fit your requirements.
				</p>
			</div>
			<div class="p-4 pt-0">
				<button
					class="group relative w-full inline-flex items-center justify-center px-4 py-2  font-bold text-white rounded-lg bg-gradient-to-r from-[#9C8A6C] to-[#B7A98F] hover:from-[#8c7961] hover:to-[#9C8A6C] shadow-lg shadow-[#B7A98F]/30 hover:shadow-[#B7A98F]/40 transition-all duration-300 hover:-translate-y-0.5"
				>
      <span class="relative flex items-center gap-2">
        Read More
        <svg
			viewBox="0 0 24 24"
			stroke="currentColor"
			fill="none"
			class="w-5 h-5 transform transition-transform group-hover:translate-x-1"
		>
          <path
			  d="M17 8l4 4m0 0l-4 4m4-4H3"
			  stroke-width="2"
			  stroke-linejoin="round"
			  stroke-linecap="round"
		  ></path>
        </svg>
      </span>
				</button>
			</div>
		</a>
	</div>


</section>
