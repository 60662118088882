<lib-bmx-toast></lib-bmx-toast>
<div class="body_wrapper {{isCheckoutPageActivated?'hide_forrex':''}} {{popupOpen?'popupOpen_body_wrapper':''}} {{popupOpenPreorder?'popupOpen_body_wrapper':''}}">
	<div class="desktop_nav_cont {{menuScrolled?'scrolled':''}} home_page_not_active {{popupOpen?'popupOpen':''}} {{popupOpenPreorder?'popupOpen':''}}">
		<fb-navigation [dataStaticLeftMenu]="staticMenuLeft" [dataStaticRightMenu]="staticMenuRight"
			[navigationData]="navigationDataFetch"></fb-navigation>
	</div>
	<div class="{{navShow?'a-main-c':'a-main-margin'}}">
		<div class="a-main a-main-margin {{!isHomePage?'home_page_not_active':''}}">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>
<fb-cookie-information *ngIf="showCookieBanner"></fb-cookie-information>
<fb-footer></fb-footer>
<!--<router-outlet></router-outlet>-->
