import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieInformationComponent } from './cookie-information/cookie-information.component';
import {ConfirmDialogComponent} from "./confirm-dialog/confirm-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";



@NgModule({
    declarations: [
        CookieInformationComponent,
		ConfirmDialogComponent
    ],
    exports: [
        CookieInformationComponent
    ],
	imports: [
		CommonModule,
		MatDialogModule
	]
})
export class MiscellaneousModule { }
