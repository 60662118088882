<section class="fb-home-caraousel-new w-full flex flex-col justify-center items-center min-h-[50vh] md:pt-10">
	<h1 class="text-3xl sm:text-5xl text-[#b7a98f] font-medium mb-6 text-center -z-10 md:hidden">
		Handwoven Artisanal <span class="text-black">Textiles</span> & <span class="text-black">Products</span>
	</h1>
	<div class="container flex flex-col-reverse lg:flex-row justify-between items-stretch gap-3 md:gap-[30px]">
		<div class="mb-3 lg:my-0  lg:flex-[45%] mx-2 lg:mx-0 px-4 lg:px-0 text-center md:text-left">
			<h1 class="text-3xl sm:text-5xl text-black md:text-[#b7a98f] font-medium mb-10 hidden md:block">
				Handwoven Artisanal <span class="text-black">Textiles</span> & <span class="text-black">Products</span>
			</h1>
			<p class="text-base md:text-2xl my-2">
				<span class="text-[#9c8a6c]">100%</span> Natural Fibres
			</p>
			<p class="text-base md:text-2xl my-2">Fully <span class="text-[#9c8a6c]">customised</span> fabrics at low MOQ</p>
			<p class="text-base md:text-2xl my-2">Seamless manufacturing <span class="text-[#9c8a6c]">Apparel</span>, <span class="text-[#9c8a6c]">Home</span> & <span class="text-[#9c8a6c]">Accessories</span></p>

			<div class="w-full flex justify-between gap-2 items-center">
				<a [routerLink]="'/products/fabric'"
				   target="_blank"
				   class="w-full bg-[#fffcf7] hover:bg-white hover:shadow-md rounded md:rounded-lg border-2 border-[#8E7862] text-[#8E7862] py-1 px-3 hover:border-[#6c5b48]
						transition flex items-center justify-center gap-2 text-sm sm:text-xl mt-4">
					<i class="fb_animate"><b ></b><span></span></i>
					Fabrics
				</a>
				<a [routerLink]="'/products/finished'"
				   target="_blank"
				   class="w-full bg-[#fffcf7] hover:bg-white hover:shadow-md rounded md:rounded-lg border-2 border-[#8E7862] text-[#8E7862] py-1 px-3 hover:border-[#6c5b48]
						transition flex items-center justify-center gap-2 text-sm sm:text-xl mt-4">
					<i class="fb_animate"><b ></b><span></span></i>
					Finished Goods
				</a>
			</div>
		</div>
		<div class="lg:flex-[55%] mx-2 lg:mx-0 px-4 lg:px-0 md:w-full flex flex-col justify-center items-center">
				<div class="fb-stack-container cursor-pointer hidden md:flex">
					<div #stack class="fb-stack">
						<div *ngFor="let card of cards; let i = index" class="fb-stack-card">
							<ng-container *ngIf="card.type === 'video'">
								<video [id]="'stackVideo'+ i"
									   class="p-2 aspect-video h-full object-cover rounded-[2rem]"
									   preload="none"
									   [poster]="card.poster"
									   [src]="card.src" [controls]="false" playsinline [muted]="true" [loop]="true"></video>
							</ng-container>
							<ng-container *ngIf="card.type === 'image'">
								<img class="p-2  md:aspect-video h-full object-cover rounded-[2rem]" lazyLoad
									 [src]="card.src" alt="">
							</ng-container>
						</div>
					</div>
				</div>
			<div class="fb-mobile-stack rounded-[2rem] bg-white md:hidden">
				<video [id]="'stackVideo'+ activeCardIndex"
					   class="p-2 aspect-video object-cover rounded-[2rem]"
					   [poster]="cards[activeCardIndex].poster"
					   preload="none"
					   [src]="cards[activeCardIndex].src" autoplay controls playsinline [muted]="true" [loop]="true"></video>
			</div>
			<p id="stack-text"
			   class="fb-stack-text text-xl md:text-2xl md:ml-8 md:mt-3 text-center"
			   [ngClass]="{'fb-stack-text-animate': typingEffect}"
			   [innerHTML]="cards[activeCardIndex].text"></p>

		</div>
	</div>
</section>
