import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RequestMapperService} from "./request-mapper.service";
import {AuthGuard} from './authentication/guard/auth.guard';
import {ProductRedirectionComponent} from "./compatibility/product-redirection/product-redirection.component";

const routes: Routes = [
	{
		path: RequestMapperService.BASE_RELATIVE_URL,
		loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
		pathMatch: 'full'
	},
	{
		path: RequestMapperService.MAINTENANCE,
		redirectTo: RequestMapperService.BASE_RELATIVE_URL,
		// loadComponent: () => import('./maintenance/maintenance/maintenance.component').then(c => c.MaintenanceComponent),
		// pathMatch: 'full'
	},
	{
		path: RequestMapperService.STORYBOARD,
		canActivate: [AuthGuard],
		loadChildren: () => import('./storyboard/storyboard.module').then(m => m.StoryboardModule)
	},
	{
		path: RequestMapperService.STORY_LISTING,
		loadChildren: () => import('./story/story.module').then(m => m.StoryModule)
	},
	{
		path: RequestMapperService.STORY_DETAILS,
		loadChildren: () => import('./story-details/story-details.module').then(m => m.StoryDetailsModule)
	},
	{
		path: RequestMapperService.PRODUCT,
		loadChildren: () => import('./product/product.module').then(m => m.ProductModule)
	},
	{
		path: RequestMapperService.PRODUCT_DETAILS,
		loadChildren: () => import('./product-details/product-details.module').then(m => m.ProductDetailsModule)
	},
	{
		path: RequestMapperService.CONTACT,
		loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
	},
	{
		canActivate: [AuthGuard],
		path: RequestMapperService.CHECKOUT,
		loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule)
	},
	{	canActivate: [AuthGuard],
		path: RequestMapperService.PROFILE,
		loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
	},
	{
		path: RequestMapperService.CUSTOM_CONTENT_URL,
		loadChildren: () => import('./custom-content/custom-content.module').then(m => m.CustomContentModule)
	},
	{
		path: RequestMapperService.SEARCH_RESULTS_URL,
		// redirectTo: RequestMapperService.MAINTENANCE,
		loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
	},
	{
	  	path: RequestMapperService.FILTER_URL,
		loadChildren: () => import('./filter/filter.module').then(m => m.FilterModule)
	},
	{
		path: RequestMapperService.AUTH,
		loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
	},
	{
		path: RequestMapperService.WISHLIST,
		loadChildren: () => import('./wishlist/wishlist.module').then(m => m.WishlistModule)
	},
	{
		path: RequestMapperService.NOT_FOUND_URL,
		loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)
	},
	{
		path: RequestMapperService.REVIEW,
		loadChildren: () => import('./review/review.module').then(m => m.ReviewModule)
	},
	{
		path: '**',
		redirectTo: RequestMapperService.BASE_RELATIVE_URL,
		// redirectTo: RequestMapperService.MAINTENANCE
	},
]

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		initialNavigation: 'enabledBlocking'
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
