import { Component } from '@angular/core';

@Component({
  selector: 'fb-home-manufacturing-process',
  templateUrl: './home-manufacturing-process.component.html',
  styleUrls: ['./home-manufacturing-process.component.scss']
})
export class HomeManufacturingProcessComponent {

}
